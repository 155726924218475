<template>
  <b-container fluid>
    <b-row>
      <b-col xs="12" v-if="!isAdmin()">
        <h2 class="text-left pl-5">Forbidden</h2>
      </b-col>

      <b-col xs="12" v-if="isAdmin()">
        <ListingEpisode
          ref="episodeList"
          v-bind:showCheckbox="false"
          v-bind:showCopyEmbed="false"
          v-bind:showDelete="true"
          v-bind:cutTitle="false"
          v-bind:item="item"
        ></ListingEpisode>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ListingEpisode from "@/components/ListingEpisode";
import auth from "@/store/auth.module";
import { mapGetters } from "vuex";

import { getPodcast } from "@/api/v1/getters";

export default {
  name: "EpisodeList",

  components: {
    ListingEpisode
  },

  data() {
    return {
      user: auth.state.user,
      timeout: 25000,
      sessions: {
        loading: true
      },
      loading: false,
      searchFilter: "",
      podcastHashId: null,
      item: {
        id: null
      }
    };
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },

  created() {
    const { id } = this.$route.params;

    this.podcastHashId = id;
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Podcasts" }]);

    this.load();
  },

  methods: {
    ...mapGetters(["isAdmin"]),

    load() {
      this.sessions.loading = true;

      getPodcast(this.podcastHashId, this.axios)
        .then(data => {
          this.item = data;
          this.podcastHashId = data.id;
        })
        .catch(() => {
          this.item = {
            id: null
          };
          this.sessions.loading = false;
        });
    }
  }
};
</script>
