import { ROLES } from "@/common/config/acl/roles";

export const dropdownMenuEmbed = [
  {
    icon: "mdi-language-html5",
    text: "Embed version 1",
    version: "v1"
  },
  {
    icon: "mdi-language-html5",
    text: "Embed version 2",
    version: "v2"
  }
];

export const dropdownMenuOEmbed = [
  {
    icon: "mdi-developer-board",
    text: "oEmbed (Embed version 1)",
    version: "v1"
  },
  {
    icon: "mdi-developer-board",
    text: "oEmbed (Embed version 2)",
    version: "v2"
  }
];

export const dropdownProgramAndPlaylist = [
  {
    action: "copy_embed",
    icon: "mdi-language-html5",
    key: "ADVANCED_SETTINGS.LISTING_EPISODES.LABEL_EMBED",
    type: "html",
    selector: "copy-embed",
    role: ""
  },
  {
    action: "copy_oembed",
    icon: "mdi-developer-board",
    key: "ADVANCED_SETTINGS.LISTING_EPISODES.LABEL_OEMBED",
    type: "o",
    selector: "copy-embed",
    role: ""
  },
  {
    action: "copy_url",
    icon: "mdi-podcast",
    key: "ADVANCED_SETTINGS.LISTING_EPISODES.LABEL_URL",
    type: "",
    selector: "copy-url",
    role: ""
  },
  {
    action: "delete",
    icon: "mdi-delete",
    key: "ADVANCED_SETTINGS.LISTING_EPISODES.LABEL_DELETE",
    type: "",
    role: ROLES.BBB_ADMIN
  },
  {
    action: "clean",
    icon: "mdi-opacity",
    key: "ADVANCED_SETTINGS.LISTING_EPISODES.LABEL_CLEAN",
    type: "",
    role: ROLES.BBB_ADMIN
  }
];
