<template>
  <b-overlay
    style="cursor: default"
    :show="disable"
    opacity="0.5"
    blur="2px"
    rounded="sm"
  >
    <template #overlay>
      <span v-show="0">d</span>
    </template>
    <v-row>
      <v-col
        cols="12"
        class="align-middle"
        justify="center"
        v-if="null === item"
      >
        <v-row>
          <v-col
            v-if="!restrictHeader"
            cols="12"
            md="6"
            class="align-middle"
            justify="center"
          >
            <v-text-field
              hide-details
              label="Add RSS podcast you want to promote"
              prepend-inner-icon="search"
              solo
              filled
              color="blue"
              dense
              clearable
              @input="inputSearchPodcastHandler"
              v-model="searchFilterPodcast"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="align-middle" justify="center">
            <v-row>
              <v-col cols="6" md="6" class="align-middle pl-5" justify="center"
                ><span class="align-middle"
                  >Podcast language : {{ podcast.language }}</span
                ></v-col
              >
              <v-col
                v-if="!restrictHeader"
                cols="6"
                md="6"
                class="align-middle py-0"
                justify="center"
              >
                <v-checkbox
                  label="Authorize international ?"
                  v-model="podcast.isInternational"
                  class="inter align-middle m-0"
                  style="font-size: 12px !important"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="text-center" v-if="true === podcastNotFound">
        <v-alert dense type="info"> No podcats found </v-alert>
      </v-col>

      <v-col cols="12 pb-0">
        <b-card
          border-variant="secondary"
          header-border-variant="secondary"
          class="h-100"
        >
          <template v-slot:header>
            <v-row>
              <v-col
                cols="12"
                sm="2"
                class="align-middle"
                justify="center"
                v-bind:style="{ margin: '12px auto' }"
                >{{ totalRows }} Episodes</v-col
              >
              <v-col cols="12" sm="10" class="align-middle" justify="center">
                <b-form-input
                  hide-details
                  label="Search episode"
                  prepend-inner-icon="search"
                  solo
                  filled
                  color="blue"
                  dense
                  clearable
                  @input="inputSearchHandler"
                  v-model="searchFilter"
                ></b-form-input>
              </v-col>
            </v-row>
          </template>

          <v-row
            :style="null === item ? 'min-height: 740px' : 'min-height: 910px'"
            :align="true === loading ? 'center' : 'start'"
            justify="center"
          >
            <v-col
              cols="12"
              v-if="true === loading"
              class="align-middle text-center"
            >
              <v-progress-circular
                indeterminate
                color="amber"
              ></v-progress-circular>
            </v-col>

            <b-table
              v-if="false === loading"
              show-empty
              stacked="md"
              :striped="striped"
              :bordered="bordered"
              :borderless="borderless"
              :outlined="outlined"
              :small="small"
              :hover="hover"
              :dark="dark"
              :fixed="fixed"
              :foot-clone="footClone"
              :no-border-collapse="noCollapse"
              :head-variant="headVariant"
              :table-variant="tableVariant"
              :items="filterItem(items)"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filterIncludedFields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              @row-clicked="checkboxChecked"
            >
              <template v-slot:head(checkbox) v-if="true === showCheckbox">
                <b-form-checkbox
                  v-model="checkboxAll"
                  class="align-middle"
                  v-on:change="checkboxCheckedAll()"
                ></b-form-checkbox>
              </template>

              <template
                v-slot:cell(checkbox)="row"
                v-if="true === showCheckbox"
              >
                <v-row>
                  <v-col>
                    <b-form-checkbox
                      v-model="row.item.selected"
                      class="align-middle"
                      v-on:change="checkboxChecked(row.item, row.index)"
                    ></b-form-checkbox>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:cell(title)="row">{{
                cutSentence(row.item.title, 300)
              }}</template>

              <template v-slot:cell(actions)="row">
                <v-row
                  class="w-100 px-1"
                  v-bind:style="{ minWidth: 'max-content' }"
                >
                  <v-col cols="4" v-if="true === showDelete">
                    <template>
                      <RemoveEpisode
                        v-on:reloadListOfEpisode="reloadListOfEpisode"
                        v-bind="{
                          btnClass: 'btn btn-secondary align-right',
                          btnContent:
                            '<i class=&quot;fas fa-trash p-2 text-danger&quot;></i>',
                          item: row.item,
                          podcast: item
                        }"
                      ></RemoveEpisode>
                    </template>
                  </v-col>

                  <v-col cols="4" v-if="true === showOnPodInstall">
                    <v-btn
                      icon
                      color="blue"
                      :href="row.item.url"
                      title="Go to listen on Podinstall.com"
                      target="_blank"
                    >
                      <v-icon>mdi-link</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:cell(action_episode_url)="row">
                <v-row
                  class="w-100 px-1 m-auto"
                  v-bind:style="{ minWidth: 'max-content' }"
                >
                  <v-col cols="4" v-if="true === showCopyURL">
                    <b-row>
                      <b-col>
                        <v-btn
                          icon
                          title="Copy episode URL"
                          @click.stop.prevent="
                            copyToClipboard(
                              row.item,
                              'url',
                              'copyURLClipboard_' + row.item.path
                            )
                          "
                        >
                          <v-icon>mdi-link</v-icon>
                        </v-btn>
                      </b-col>
                    </b-row>
                    <input
                      type="hidden"
                      v-bind:id="'copyURLClipboard_' + row.item.path"
                      class="form-control"
                      style="width: 100px"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:cell(action_html)="row">
                <v-row
                  class="w-100 px-1 m-auto"
                  v-bind:style="{ minWidth: 'max-content' }"
                >
                  <v-col cols="4" v-if="true === showCopyEmbed">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          fab
                          :elevation="0"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="black">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in getDropdownMenuEmbed"
                          :key="`embed_${index}`"
                          link
                        >
                          <v-list-item-title
                            @click.stop.prevent="
                              copyToClipboard(
                                row.item,
                                'html',
                                'copyEmbedClipboard_' + row.item.path,
                                item.version
                              )
                            "
                            ><v-icon>{{ item.icon }}</v-icon>
                            {{ item.text }}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <input
                      type="hidden"
                      v-bind:id="'copyEmbedClipboard_' + row.item.path"
                      class="form-control"
                      style="width: 100px"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:cell(action_oembed)="row">
                <v-row
                  class="w-100 px-1 m-auto"
                  v-bind:style="{ minWidth: 'max-content' }"
                >
                  <v-col cols="4" v-if="true === showCopyOEmbed">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          fab
                          :elevation="0"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="black">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in getDropdownMenuOEmbed"
                          :key="`oembed_${index}`"
                          link
                        >
                          <v-list-item-title
                            @click.stop.prevent="
                              copyToClipboard(
                                row.item,
                                'oEmbed',
                                'copyEmbedClipboard_' + row.item.path,
                                item.version
                              )
                            "
                            ><v-icon>{{ item.icon }}</v-icon>
                            {{ item.text }}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <input
                      type="hidden"
                      v-bind:id="'copyOEmbedClipboard_' + row.item.path"
                      class="form-control"
                      style="width: 100px"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:cell(action_imgcleaner)="row" v-if="isAdmin()">
                <v-row
                  class="w-100 px-1 m-auto"
                  v-bind:style="{ minWidth: 'max-content' }"
                >
                  <v-col cols="4">
                    <b-row>
                      <b-col>
                        <!-- <v-btn
                          icon
                          color="red"
                          title="Clean episode image"
                          :loading="row.item.loading.cleaner"
                          @click.stop.prevent="imageCleaner(row.item)"
                        >
                          <v-icon small>mdi-flash</v-icon>
                        </v-btn> -->
                        <button
                          v-if="!row.item.loading.cleaner"
                          title="Clean episode image"
                          @click.stop.prevent="imageCleaner(row.item)"
                          class="btn btn-icon btn-light-danger"
                        >
                          <inline-svg
                            src="assets/media/icons/svg/Files/Folder-thunder.svg"
                          />
                        </button>
                        <v-progress-circular
                          v-if="row.item.loading.cleaner"
                          indeterminate
                          color="red"
                        ></v-progress-circular>
                      </b-col>
                    </b-row>
                  </v-col>
                </v-row>
              </template>
            </b-table>
          </v-row>

          <template v-slot:footer>
            <b-row>
              <b-pagination
                v-bind:style="{ margin: '0 auto' }"
                align="center"
                justify="center"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-row>
          </template>
        </b-card>
      </v-col>
    </v-row>
  </b-overlay>
</template>

<style>
.v-input.inter label {
  font-size: 12px !important;
  margin-top: 5px;
}
</style>

<script>
import { mapGetters } from "vuex";
import RemoveEpisode from "@/views/partials/widgets/RemoveEpisode";
import auth from "@/store/auth.module";
import Config from "@/common/config";
import {
  dropdownMenuEmbed,
  dropdownMenuOEmbed
} from "@/components/episodes/embed/dropdown.menu.config";

import { getProgramEpisodes } from "@/api/v1/getters";

export default {
  name: "ListingEpisode",

  components: {
    RemoveEpisode
  },

  props: {
    showOnPodInstall: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    showCopyEmbed: {
      type: Boolean,
      default: false
    },
    showCopyOEmbed: {
      type: Boolean,
      default: false
    },
    showCopyURL: {
      type: Boolean,
      default: false
    },
    showCopyEmbedURL: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    cutTitle: {
      type: Boolean,
      default: true
    },
    itemsSelected: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disable: {
      type: Boolean,
      default: false
    },
    feedHash: {
      type: String,
      default: () => {
        return "";
      }
    },
    item: {
      type: Object,
      default: () => {
        return null;
      }
    },
    isInternational: {
      type: Boolean,
      default: false
    },
    restrictHeader: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      user: auth.state.user,
      podcastId: null,
      timeout: 5000,
      dialog: {
        delete: {
          status: false,
          loading: false,
          item: null,
          index: -1
        }
      },
      clipboardContent: "",
      loading: false,
      searchFilter: "",
      searchFilterPodcast: "",
      items: [],
      fields: [
        {
          key: "checkbox",
          sortable: false,
          sortDirection: "desc",
          class: "text-center",
          tdClass: "text-center p-0"
        },
        {
          key: "title",
          label: "Title",
          sortable: true,
          sortDirection: "desc",
          tdClass: "text-left align-middle w-100"
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          sortDirection: "desc",
          class: "text-center align-middle",
          tdClass: "text-center table-select-w p-0"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [10, 15, 30, 50],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      tableVariants: [
        "primary",
        "secondary",
        "info",
        "danger",
        "warning",
        "success",
        "light",
        "dark"
      ],
      striped: true,
      bordered: false,
      borderless: false,
      outlined: false,
      small: false,
      hover: true,
      dark: false,
      fixed: false,
      footClone: false,
      headVariant: null,
      tableVariant: "",
      noCollapse: false,
      checkboxAll: false,
      elements: [],
      podcastNotFound: false,
      podcast: {
        language: null,
        isInternational: false
      }
    };
  },

  watch: {
    feedHash: function() {
      this.searchFilterPodcast = this.feedHash;
      this.load();
    },
    item: function() {
      if (null !== this.item) {
        this.load();
      }
    },
    isInternational: function() {
      this.podcast.isInternational = this.isInternational;
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    piDomain() {
      return Config.get("platform_listening");
    },
    getDropdownMenuEmbed() {
      return dropdownMenuEmbed;
    },
    getDropdownMenuOEmbed() {
      return dropdownMenuOEmbed;
    }
  },

  mounted() {
    let fields = this.fields;
    if (false === this.showCheckbox) {
      fields.shift();
    }
    if (false === this.showDelete) {
      fields.pop();
    }
    if (true === this.showCopyURL) {
      fields.push({
        key: "action_episode_url",
        label: "Copy episode URL",
        sortable: false,
        sortDirection: "desc",
        class: "text-center align-middle",
        tdClass: "text-center table-select-w p-0"
      });
    }
    if (true === this.showCopyEmbed) {
      fields.push({
        key: "action_html",
        label: "Copy embed HTML",
        sortable: false,
        sortDirection: "desc",
        class: "text-center align-middle",
        tdClass: "text-center table-select-w p-0"
      });
    }
    if (true === this.showCopyOEmbed) {
      fields.push({
        key: "action_oembed",
        label: "oEmbed URL",
        sortable: false,
        sortDirection: "desc",
        class: "text-center align-middle",
        tdClass: "text-center table-select-w p-0"
      });
    }
    if (this.isAdmin()) {
      fields.push({
        key: "action_imgcleaner",
        label: "Clean episode image",
        sortable: false,
        sortDirection: "desc",
        class: "text-center align-middle",
        tdClass: "text-center table-select-w p-0"
      });
    }
    this.fields = fields;

    if (this.$route.params.id) {
      this.podcastId = this.$route.params.id;
    } else {
      this.podcastId = this.item ? this.item.id : null;
    }
    if (this.items.length === 0 && this.feedHash !== "") {
      this.searchFilterPodcast = this.feedHash;
      this.load();
    }
  },

  methods: {
    ...mapGetters(["isAdmin"]),

    imageCleaner(episode) {
      const rows = this.items.map(row => {
        if (episode.path === row.path) {
          return {
            ...row,
            loading: {
              ...row.loading,
              cleaner: true
            }
          };
        }

        return row;
      });

      this.items = rows;

      this.$bvToast.toast(`Image not cleaned`, {
        title: `Image v1 cleaner`,
        solid: true,
        variant: "warning",
        appendToast: false,
        autoHideDelay: 3000
      });
    },

    load() {
      if (null !== this.item) {
        this.perPage = 10;
      }

      this.loading = true;
      this.podcastNotFound = false;

      getProgramEpisodes(
        this.podcastId,
        {
          search: encodeURI(this.searchFilterPodcast)
        },
        this.axios
      )
        .then(data => {
          let elements = [];
          if (data.hasOwnProperty("counter") && data.hasOwnProperty("rows")) {
            this.items = data.rows.map(row => {
              let selected = false;

              if (-1 !== this.itemsSelected.indexOf(row.path)) {
                elements.push(row.path);
                selected = true;
              }

              return {
                ...row,
                selected: selected,
                loading: {
                  cleaner: false
                }
              };
            });
          } else if (data.hasOwnProperty("podcast")) {
            const episodes = data.podcast.episodes;

            this.podcast = {
              ...this.podcast,
              language: data.podcast.language ? data.podcast.language : "fr"
            };

            this.totalRows = episodes.length;
            this.items = episodes.map(row => {
              let selected = false;

              if (-1 !== this.itemsSelected.indexOf(row.path)) {
                elements.push(row.path);
                selected = true;
              }

              return {
                ...row,
                selected: selected,
                loading: {
                  cleaner: false
                }
              };
            });
          } else if (Array.isArray(data)) {
            this.totalRows = data.length;
            this.items = data.map(row => {
              let selected = false;

              if (-1 !== this.itemsSelected.indexOf(row.path)) {
                elements.push(row.path);
                selected = true;
              }

              return {
                ...row,
                selected: selected,
                loading: {
                  cleaner: false
                }
              };
            });
          } else {
            this.totalRows = 0;
            this.items = [];
          }
          this.elements = elements;
          this.checkboxAll =
            this.elements.length == this.items.length ? true : false;
        })
        .catch(() => {
          // TODO: Print error
        })
        .finally(() => {
          this.loading = false;
          this.$emit("episodesloaded");
          if (null === this.item && 0 === this.items.length) {
            this.podcastNotFound = true;
          }
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    inputSearchPodcastHandler(text) {
      if (text && text.length > 2) {
        this.load();
      } else {
        this.totalRows = 0;
        this.items = [];
      }
    },
    inputSearchHandler(text) {
      if (text && text.length > 2) {
        this.searchFilter = text.toLowerCase();
      } else {
        this.searchFilter = "";
      }
    },
    filterItem(items) {
      let rows = items;

      if (this.searchFilter.length > 0) {
        rows = items.filter(item =>
          item.title.toLowerCase().includes(this.searchFilter)
        );
      }

      this.totalRows = rows.length;

      return rows;
    },
    cutSentence(sentence, len) {
      let cutS = sentence.substring(0, len);
      if (40 < sentence.length && this.cutTitle) {
        cutS += "...";
      }
      return cutS;
    },
    checkLast() {
      let items = this.items;
      const rows = items.map((row, key) => {
        return {
          ...row,
          selected: key == 0 ? true : false
        };
      });
      this.items = rows;
    },
    checkSelected() {
      let items = this.items;
      //this.items[0].selected = true;
      const rows = items.map(row => {
        return {
          ...row,
          selected: -1 !== this.itemsSelected.indexOf(row.path) ? true : false
        };
      });
      this.items = rows;
    },
    forceCheckAll() {
      let items = this.items;
      const rows = items.map(row => {
        return {
          ...row,
          selected: true
        };
      });
      this.items = rows;
    },
    checkboxCheckedAll() {
      let items = this.items;
      let status = this.checkboxAll ? false : true;
      let elements = [];

      const rows = items.map(row => {
        if (status) {
          elements.push(row.path);
        }

        return {
          ...row,
          selected: status
        };
      });

      this.checkboxAll = elements.length == items.length ? true : false;
      this.elements = elements;
      this.items = rows;
    },
    checkboxChecked(item) {
      let items = this.items;
      let elements = this.elements;
      let status = false;

      const idx = elements.indexOf(item.path);

      if (-1 === idx) {
        elements.push(item.path);
        status = true;
      } else {
        elements.splice(idx, 1);
      }

      const rows = items.map(row => {
        if (item.path === row.path) {
          return {
            ...row,
            selected: status
          };
        }

        return row;
      });

      this.checkboxAll = elements.length == items.length ? true : false;
      this.elements = elements;
      this.items = rows;
    },
    copyToClipboard(item, type, id, embedVersion) {
      let content = "";

      switch (type) {
        case "html":
          content =
            embedVersion in item.embed_html
              ? item.embed_html[embedVersion]
              : item.embed_html["v1"];
          break;
        case "url":
          content = item.URL;
          break;
        case "url_embed":
          content =
            embedVersion in item.embed_url
              ? item.embed_url[embedVersion]
              : item.embed_url["v1"];
          break;
        case "oEmbed":
          content =
            embedVersion in item.oembed
              ? item.oembed[embedVersion]
              : item.oembed["v1"];
          break;

        default:
          break;
      }

      console.warn("copyToClipboard", content);

      let copyContent = document.querySelector(`#${id}`);
      copyContent.value = content;
      copyContent.setAttribute("type", "text");
      copyContent.focus();

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        copyContent.contentEditable = true;
        copyContent.readOnly = true;

        const range = document.createRange();
        range.selectNodeContents(copyContent);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        copyContent.setSelectionRange(0, 999999);
      } else {
        copyContent.select();
      }

      let message = "success";
      let variant = "success";
      let autoHideDelay = 3000;

      try {
        document.execCommand("Copy");
      } catch (err) {
        message = "unknown error";
        variant = "danger";
      }

      this.$bvToast.toast(`Copy to clipboard`, {
        title: `Copy embed to clipboard ${message}`,
        toaster: "b-toaster-bottom-right",
        solid: true,
        variant: variant,
        appendToast: false,
        autoHideDelay: autoHideDelay
      });

      copyContent.setAttribute("type", "hidden");
    },
    reloadListOfEpisode() {
      setTimeout(() => this.load(), 500);
    }
  }
};
</script>
