<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn icon color="orange darken-2" title="Delete episode" v-on="on">
        <v-icon>mdi-delete-forever</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Remove episode from podcast :</span>
      </v-card-title>

      <v-card-text
        >Your episode will be deleted from your podcast. Are you sure
        ?</v-card-text
      >

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="primary" class="mx-1" v-on:click="onNo">No</v-btn>
        <v-btn
          type="submit"
          class="mx-1 btn"
          style="
             {
              border-color: #1c195c;
              color: #ffffff;
              background-color: #1c195c;
            }
          "
          :loading="loading"
          :disabled="loading"
          v-on:click="onYes"
          >yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "RemoveEpisode",

  props: {
    btnContent: String,
    btnClass: String,
    item: Object,
    podcast: Object
  },

  data() {
    return {
      timeout: 25000,
      dialog: false,
      loader: null,
      loading: false
    };
  },

  methods: {
    onYes() {
      this.deleteFeed();
    },
    onNo() {
      this.dialog = false;
    },
    deleteFeed() {
      this.loading = true;

      const baseURL = `/v1/podcasts/${this.podcast.id}/episodes/${this.item.path}`;

      ApiService.delete(`${baseURL}`, { timeout: this.timeout })
        .then(() => {
          this.loading = false;
          this.dialog = false;

          this.$emit("reloadListOfEpisode");
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
